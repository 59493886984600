export const SOUNDS = {
    BUTTON_CLICK_SOUND: new Audio('assets/button-click.mp3'),
    BUY_TICKET_SOUND: new Audio('assets/buy-tickets.mp3'),
    CLUB_OPEN_SOUND: new Audio('assets/club-open.mp3'),
    NUMBER_CLICK_SOUND: new Audio('assets/number-click.mp3'),
  };
  
  const PLAY_BUTTON_CLICK_SOUND= () => {
    SOUNDS.BUTTON_CLICK_SOUND.volume = 0.5;
    SOUNDS.BUTTON_CLICK_SOUND.play();
  }
  const PLAY_BUY_TICKET_SOUND= () => {
    SOUNDS.BUY_TICKET_SOUND.volume = 0.5;
    SOUNDS.BUY_TICKET_SOUND.play();
  }
  const PLAY_CLUB_OPEN_SOUND= () => {
    SOUNDS.CLUB_OPEN_SOUND.volume = 0.5;
    SOUNDS.CLUB_OPEN_SOUND.play();
  }
  const PLAY_NUMBER_CLICK_SOUND= () => {
    SOUNDS.NUMBER_CLICK_SOUND.volume = 0.5;
    SOUNDS.NUMBER_CLICK_SOUND.play();
  }
  
  export {
    PLAY_BUTTON_CLICK_SOUND,
    PLAY_BUY_TICKET_SOUND,
    PLAY_CLUB_OPEN_SOUND,
    PLAY_NUMBER_CLICK_SOUND
  };
  