import { useProfileContext } from "../App";
import { buyTicketAPI } from "../api/api";
import { JackpotAC, JackpotBike, JackpotCar, JackpotGold, JackpotIphone } from "../constants/assets/Icons";
import { PLAY_BUTTON_CLICK_SOUND, PLAY_BUY_TICKET_SOUND } from "../constants/audio";
import toast from "react-hot-toast";
import { BiSolidLeftArrowAlt } from "react-icons/bi";

export const Jackpot = ({onClose, city, position, time}) => {
    const { user, setUser } = useProfileContext();

    const buyTicket = async (amount: number): Promise<any> => {
        try {
            const res = await buyTicketAPI({
                tickets: [
                    {
                        amount: amount,
                        ticket: "777",
                        time,
                        position,
                    },
                ],
                place: city,
            });
            PLAY_BUY_TICKET_SOUND();
            toast.success(res.data?.message || "Purchase Successful");
            setUser({ ...user, amount: user.amount - res.data?.amount });
            return res;
        } catch (error) {
            console.log(`Error logging user: ${error}`);
            toast.error(error.response?.data?.message || "Unknown error", { id: "unknown-error" });
            throw new Error(`Error logging user: ${error}`);
        }
    };

    return (
        <section className="pt-5 z-10 flex flex-col items-center justify-start text-white gap-10 min-h-screen">
            <div className="fixed top-0 py-4  grid grid-cols-5 justify-center place-items-center w-full bg-[#030e03]">
                <button
                    onClick={() => {
                        onClose();
                        PLAY_BUTTON_CLICK_SOUND();
                    }}
                    className="p-3 bg-gray-100 rounded-full shadow-sm"
                >
                    <BiSolidLeftArrowAlt className="w-full text-black" />
                    {/* <FontAwesomeIcon icon="fa-solid fa-arrow-left" /> */}
                </button>
                <span className="text-2xl tracking-wide font-sans font-semibold col-span-3 oleo-script">
                    Buy Jackpot
                </span>
                <div />
            </div>
            <div className="flex pt-32 flex-wrap justify-center items-center w-full gap-5 my-5">
                <button
                    disabled={user.amount < 100}
                    onClick={() => buyTicket(100)}
                    className="disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer"
                >
                    <JackpotAC />
                </button>
                <button
                    disabled={user.amount < 150}
                    onClick={() => buyTicket(150)}
                    className="disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer"
                >
                    <JackpotIphone />
                </button>
                <button
                    disabled={user.amount < 250}
                    onClick={() => buyTicket(250)}
                    className="disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer"
                >
                    <JackpotBike />
                </button>
                <button
                    disabled={user.amount < 1000}
                    onClick={() => buyTicket(1000)}
                    className="disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer"
                >
                    <JackpotGold />
                </button>
                <button
                    disabled={user.amount < 2500}
                    onClick={() => buyTicket(2500)}
                    className="disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer"
                >
                    <JackpotCar />
                </button>
                
            </div>
        </section>
    );
};
