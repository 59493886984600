import { CLUBS, routes } from "../../constants";
import { PLAY_CLUB_OPEN_SOUND } from "../../constants/audio";
import { NavLink } from "react-router-dom";

const City = () => {
    return (
        <div className="flex flex-wrap justify-center items-center w-full gap-5 py-5">
            {CLUBS.map((event) => (
                <NavLink
                    to={`${routes.PLACE_BID}/${event.place}`}
                    key={event.place}
                    onClick={() => PLAY_CLUB_OPEN_SOUND()}
                    className="cursor-pointer"
                >
                        {event.icon}
                </NavLink>
            ))}
        </div>
    );
};

export default City;
