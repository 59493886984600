import { CLUBS, routes } from "../constants";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import BidModal from "./BidModal";
import { cn } from "../utils/cn";
import { useProfileContext } from "../App";
import {
    DoubleBet,
    FullKing,
    HalfKing,
    JackpotPlay,
    SingleBet,
    TripleBet,
} from "../constants/assets/Icons";
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import { PLAY_BUTTON_CLICK_SOUND, PLAY_CLUB_OPEN_SOUND, PLAY_NUMBER_CLICK_SOUND } from "../constants/audio";
import { adjustToNepaliTime } from "../utils/adjustTime";
import { Jackpot } from "./Jackpot";

export const PlaceBid = () => {
    const navigate = useNavigate();
    const { city } = useParams();
    const [isModalOpen, setIsModalOpen] = useState(null);
    const { user } = useProfileContext();
    const [selectedTime, setSelectedTime] = useState(null);
    const [position, setPosition] = useState(null);
    const [showSplash, setShowSplash] = useState(true); 
    const [isJackpotOpen, setIsJackpotOpen] = useState(false); 

    const handleCloseModal = () => {
        setIsModalOpen(null);
    };

    useEffect(() => {
        if (!user) navigate(routes.LOGIN);
        const timer = setTimeout(() => setShowSplash(false), 3000); // Hide splash screen after 3 seconds
        return () => clearTimeout(timer);
    }, [navigate, user]);

    return (
        <>
            {showSplash && (
                <div className="flex justify-center items-center w-full h-full fixed top-0 left-0 z-50"
                    style={{
                        animation: 'zoomFadeOut 1s forwards',
                    }}
                >
                        {CLUBS.find((event) => event.place === city).icon}
                </div>
            )}
            {isModalOpen ? (
                <BidModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    time={selectedTime}
                    position={position}
                    city={city}
                />
            ) : 
            isJackpotOpen ? (
                <Jackpot 
                    onClose={() => setIsJackpotOpen(false)}
                    city={city}
                    position={position}
                    time={selectedTime}
                />
            ):(
                <section className=" flex flex-col items-center py-8 min-h-screen ">
                    <div className="fixed top-0 p-4 grid grid-cols-5 justify-center place-items-center w-full bg-[#030e03]">
                        <button
                        onClick={() => {
                            PLAY_BUTTON_CLICK_SOUND();
                            navigate(routes.INDEX)}}
                    className="p-3 bg-gray-100 rounded-full shadow-sm"
                >
                <BiSolidLeftArrowAlt className="w-full text-black"/>
                    {/* <FontAwesomeIcon icon="fa-solid fa-arrow-left" /> */}
                </button>
                        <span className="text-2xl font-sans font-semibold col-span-3 oleo-script">
                            {city}
                        </span>
                        <div />
                    </div>
                    <form className="flex flex-col pt-32 w-full items-center gap-8 p-4">
                        <div className="px-3 w-full">
                            <span className="text-xl font-medium">Select time</span>
                            <div className="flex my-6 justify-between border-1 border-red-900 rounded-full">
                                {CLUBS.find((event) => event.place === city).time.map(
                                    (timestamp, index) => {
                                        const time = adjustToNepaliTime(timestamp);

                                        return (
                                            <>
                                                <input
                                                    onChange={(e) =>
                                                        setSelectedTime(Number(e.target.value))
                                                    }
                                                    className="btn-check"
                                                    type="radio"
                                                    name="betDate"
                                                    id={`betDate${index}`}
                                                    value={time}
                                                    autoComplete="off"
                                                    onClick={() => PLAY_NUMBER_CLICK_SOUND()}
                                                    required
                                                    disabled={
                                                        new Date(time).setMinutes(
                                                            new Date(time).getMinutes() +
                                                                (1 * 60 - 15)
                                                        ) < Date.now()
                                                    }
                                                />
                                                <label
                                                    aria-disabled
                                                    className={cn(
                                                        "btn py-2 text-white text-[14px] rounded-full",
                                                        selectedTime == time &&
                                                            "text-white !bg-orange-500",
                                                        new Date(time).setMinutes(
                                                            new Date(time).getMinutes() - 15
                                                        ) < Date.now() &&
                                                            new Date(time).setMinutes(
                                                                new Date(time).getMinutes() +
                                                                    (1 * 60 - 15)
                                                            ) > Date.now() &&
                                                            "text-yellow-400"
                                                    )}
                                                    htmlFor={`betDate${index}`}
                                                >
                                                    {new Date(time).toLocaleString("default", {
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                    })}
                                                </label>
                                            </>
                                        );
                                    }
                                )}
                            </div>
                            <span className="text-xl font-medium">Select Position</span>
                            <div className="flex mt-6 justify-between w-[60%] border-1 border-red-800 rounded-full">
                                <input
                                    onChange={(e) => setPosition(e.target.value)}
                                    className="btn-check"
                                    type="radio"
                                    name="bet-position"
                                    id="bet-open"
                                    value="Open"
                                    autoComplete="off"
                                    onClick={() => PLAY_NUMBER_CLICK_SOUND()}
                                    required
                                    disabled={
                                        !selectedTime ||
                                        new Date(selectedTime).setMinutes(
                                            new Date(selectedTime).getMinutes() - 15
                                        ) < Date.now()
                                    }
                                />
                                <label
                                    className={cn(
                                        "btn py-2 text-lg px-8 text-white rounded-full",
                                        position === "Open" && "!bg-orange-500"
                                    )}
                                    htmlFor="bet-open"
                                >
                                    Open
                                </label>
                                <input
                                    onChange={(e) => setPosition(e.target.value)}
                                    className="btn-check"
                                    type="radio"
                                    name="bet-position"
                                    id="bet-close"
                                    value="Close"
                                    onClick={() => PLAY_NUMBER_CLICK_SOUND()}
                                    autoComplete="off"
                                    required
                                    disabled={!selectedTime}
                                />
                                <label
                                    className={cn(
                                        "btn py-2 px-8 text-lg text-white rounded-full",
                                        position === "Close" && " !bg-orange-500"
                                    )}
                                    htmlFor="bet-close"
                                >
                                    Close
                                </label>
                            </div>
                        </div>
                    </form>
                    <div className="flex flex-wrap justify-center items-center w-full gap-5 my-5 p-5">
                        <button
                            disabled={!selectedTime || !position}
                            onClick={() => {
                                PLAY_CLUB_OPEN_SOUND();
                                setIsJackpotOpen(true)}}
                            className="disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer"
                        >
                            <JackpotPlay />
                        </button>
                        <button
                            disabled={!selectedTime || !position}
                            onClick={() => {
                                PLAY_CLUB_OPEN_SOUND();
                                setIsModalOpen("single")}}
                            className="disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer"
                        >
                            <SingleBet />
                        </button>
                        <button
                            disabled={!selectedTime || !position || position === "Close"}
                            onClick={() => {
                                PLAY_CLUB_OPEN_SOUND();
                                setIsModalOpen("double")}}
                            className="disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer"
                        >
                            <DoubleBet />
                        </button>
                        <button
                            disabled={!selectedTime || !position}
                            onClick={() => {
                                PLAY_CLUB_OPEN_SOUND();
                                setIsModalOpen("triple")}}
                            className="disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer"
                        >
                            <TripleBet />
                        </button>

                        <button
                            disabled={!selectedTime || !position}
                            onClick={() => {
                                PLAY_CLUB_OPEN_SOUND();
                                setIsModalOpen('half-king')}}
                            className="disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer"
                        >
                            <HalfKing />
                        </button>
                        <button
                            disabled={!selectedTime || !position}
                            onClick={() => {
                                PLAY_CLUB_OPEN_SOUND();
                                setIsModalOpen('full-king')}}
                            className="disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer"
                        >
                            <FullKing />
                        </button>
                    </div>
                </section>
            )}
        </>
    );
};
