export const adjustToNepaliTime = (timestamp: string) => {
    const [day, hours, minutes] = timestamp.split(":").map(Number);
    const nepalOffsetHours = 5;
    const nepalOffsetMinutes = 45;
  
    // Create a new Date object for the current time
    const date = new Date();
  
    // Adjust to UTC
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  
    // Add Nepal's offset
    date.setHours(date.getHours() + nepalOffsetHours);
    date.setMinutes(date.getMinutes() + nepalOffsetMinutes);
  
    // Correctly adjust the date object to the specified time in the timestamp, considering Nepal's time zone
    date.setHours(day * 24 + hours); // Set hours from timestamp
    date.setMinutes(minutes); // Set minutes from timestamp
    date.setSeconds(0); // Reset milliseconds
    date.setMilliseconds(0); // Reset milliseconds
  
    return date.getTime(); // Return the adjusted time in milliseconds
  };

        //   // Format local time for display
        //   const localTimeDisplay = localTime.toLocaleString("default", {
        //     month: "long",
        //     hour: "numeric",
        //     minute: "numeric",
        //     day: "2-digit",
        // });